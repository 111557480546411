'use strict'

import axios from 'axios'
import { config } from 'variables/Config.js'
import AuthService from './auth.service'
import authHeader from './auth-header'

const axiosClient = axios.create()

axiosClient.defaults.baseURL = config.SERVER_URL

axiosClient.defaults.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

// All request will wait n milliseconds before timeout
axiosClient.defaults.timeout = 0

axiosClient.interceptors.response.use(
  function (response) {
    // Dispatch any action on success
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      // Add Logic to
      // 1. Redirect to login page or
      // 2. Request refresh token
      AuthService.logout()
    }
    return Promise.reject(error)
  }
)

const fetcher = url => axiosClient.get(url).then(res => {
  return res.data
})

const fetcherAuth = (url, token) => axiosClient.get(url, token).then(res => {
  return res.data
})

// const fetcherAuth = async (url, token) => {
//   const res = await axiosClient.get(url, token).catch(function (error) {
//     const err = new Error('Errore di ricezione dei dati.')
//     if (error.response) {
//       // The request was made and the server responded with a status code
//       // that falls out of the range of 2xx
//       err.status = error.response.status
//       err.info = error.response.data
//     } else if (error.request) {
//       // The request was made but no response was received
//       // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//       // http.ClientRequest in node.js
//       console.log(error.request)
//       err.info = { message: 'Nessuna risposta dal server ' }
//     } else {
//       // Something happened in setting up the request that triggered an Error
//       console.log('Error', error.message)
//     }
//     throw err
//   })

//   return res.data
// }

export default {
  axiosClient,
  fetcher,
  fetcherAuth
}
