'use strict'
import { useState, useEffect } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Stack from '@mui/material/Stack'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'

// zerouno
import troubleTicketsService from 'services/troubleTicket.service'
import enumerationService from 'services/enumeration.service'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RegistryNav from './RegistryNav'
import utils from 'utils/utils.js'
import ZuDataTable from 'components/ZeroUno/ZuDataTable'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import authService from 'services/auth.service'

export default function TroubleTicketTable() {
  const location = useLocation()

  const [objId, setObjId] = useState(0)
  // const { idRegistry } = useParams()
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)
  // const { data: objects, isLoading, error } = troubleTicketsService.findOpen(idRegistry)
  // const { data: objects, isLoading, error } = troubleTicketsService.findAll(idRegistry)
  const [objects, setObjects] = useState([])
  const [viewAll, setViewAll] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const idRegistry = new URLSearchParams(useLocation().search).get('registryId')
  const loggedUser = authService.user()

  useEffect(() => {
    fetchObjects()
  }, [location])

  useEffect(() => {
    toggleView()
  }, [objects])

  const fetchObjects = () => {
    troubleTicketsService.findAll(idRegistry).then(
      response => {
        setObjects(
          response.data.sort(function (a, b) {
            return new Date(b.updatedAt) - new Date(a.updatedAt)
          })
        )
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const toggleView = () => {
    if (viewAll) {
      const filtered = objects.filter(item => item.stateId !== 3)
      setFilteredData(filtered)
    }
    if (!viewAll) {
      setFilteredData(objects)
    }
    setViewAll(!viewAll)
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    troubleTicketsService.remove(objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        troubleTicketsService.refresh(idRegistry)
        troubleTicketsService.refresh()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      header: 'n.',
      accessorKey: 'id',
      size: 10
    },
    {
      header: 'new',
      size: 5,
      accessorFn: d => {
        if (d.ttusers?.lenght === 0 || !d.ttusers?.some(tt => (tt.userId === loggedUser.id))) {
          return (
            <Tooltip title='non letto'>
              <Icon fontSize='large' color='info'>new_releases</Icon>
            </Tooltip>
          )
        }
      }
    },
    {
      header: 'stato',
      size: 5,
      accessorFn: d => {
        return (
          <Tooltip title={d.state?.description}>
            <Icon color={d.stateId === 1 ? 'success' : d.stateId === 2 ? 'info' : d.stateId === 3 ? 'error' : 'warning'}>circle</Icon>
          </Tooltip>
        )
      }
    },
    ...(!idRegistry
      ? [
        {
          header: 'cliente',
          size: 50,
          accessorFn: d => d.registry.businessName.trim()
        }
      ]
      : []),
    {
      header: 'oggetto',
      accessorKey: 'subject',
      size: 50
    },
    {
      header: 'modificato',
      size: 30,
      accessorFn: d => {
        return utils.formatDate(d.updatedAt, 'dd/MM/yyyy HH:mm:ss')
      }
    },
    {
      header: 'assegnato a',
      size: 50,
      accessorFn: d => {
        return d.toUser ? d.toUser.surname + ' ' + d.toUser.name : ''
      }
    },
    {
      header: 'scad.',
      size: 30,
      accessorFn: d => {
        return d.expiryDate
          ? utils.formatDate(d.expiryDate, 'dd/MM/yyyy HH:mm:ss')
          : ''
      }
    },
    {
      header: 'azioni',
      enableSorting: false,
      enableColumnFilter: false,
      accessorKey: 'id',
      size: 30,
      Cell: ({ cell }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/troubleTickets/${cell.getValue()}`}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          {false && (
            <Grid item>
              <MDButton
                iconOnly
                color='error'
                size='small'
                onClick={() => {
                  setObjId(cell.getValue())
                  setOpenConfirm(true)
                }}
              >
                <Icon>delete</Icon>
              </MDButton>
            </Grid>
          )}
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={idRegistry ? 10 : 12}>
            <Card>
              <ZUTitleForm
                bgColor='info'
                title='Trouble Tickets'
                icon='place'
              />

              <MDBox p={3} lineHeight={1}>
                <MDButton
                  color='info'
                  component={Link}
                  to='/troubletickets/new'
                  m={1}
                >
                  nuovo
                </MDButton>
                <MDButton
                  color='success'
                  onClick={() => toggleView()}
                  m={1}
                  sx={{ ml: 1 }}
                >
                  {viewAll ? 'solo aperti' : 'vedi tutti'}
                </MDButton>
              </MDBox>
              <ZuDataTable table={{ columns: columns, rows: filteredData }} />
              <MDBox pl={3} pb={2} pt={2}>
                <Stack direction='row' spacing={1} sx={{ fontSize: 14 }}>
                  <Icon color='success'>circle</Icon> nuovo
                  <Icon color='info'>circle</Icon> aperto
                  <Icon color='warning'>circle</Icon> attesa cliente
                  <Icon color='error'>circle</Icon> chiuso
                </Stack>
              </MDBox>
            </Card>
          </Grid>
          {idRegistry && (
            <Grid item xs={12} sm={12} md={2}>
              <RegistryNav id={idRegistry} />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
